import {Button, styled} from '@mui/material'

const StyledButton = styled(Button)(({theme, color}) => ({
  minHeight: '42px',
  padding: '8px 32px',
  ['@media (hover: none)']: {
    ':hover': {
      backgroundColor: theme.palette[color].main
    }
  },
  ['@media (hover: hover)']: {
    ':hover': {
      backgroundColor: theme.palette[color].light
    }
  },
  '&:disabled': {
    backgroundColor: theme.palette[color].disabled
  },
  '&:hover:not(:disabled)': {
    backgroundColor: theme.palette[color].light
  }
}))

export default StyledButton
